Vue.filter("getProp5Images", function(item)
{
    const result = [];

    if (item && item.variationProperties)
    {
        item.variationProperties.forEach((propertyGroup) =>
        {
            if (propertyGroup.properties)
            {
                const variationProperty = propertyGroup.properties.find(prop => prop.id == 5);

                if (variationProperty && variationProperty.values && variationProperty.values.value)
                {
                    const arr = variationProperty.values.value.split(";");

                    for (let i = 0; i < arr.length; i += 2)
                    {
                        if (arr[i + 1])
                        {
                            result.push([arr[i], arr[i + 1]]);
                        }
                        else
                        {
                            result.push([arr[i]]);
                        }
                    }
                }
            }
        });
    }
    return result;
});

Vue.filter("commaToDot", function(price)
{
    return price.replace(/,/g, ".");
});

Vue.filter("currencyKH", function(price)
{
    if (price === "N / A")
    {
        return price;
    }

    const formatter = new MonetaryFormatterKuheiga();

    return formatter.format(parseFloat(price), App.activeCurrency);
});

function isNull(object)
{
    return object === null;
}

function isUndefined(object)
{
    // eslint-disable-next-line
    return typeof object === typeof void 0;
}

function isNullOrUndefined(object)
{
    return isNull(object) || isUndefined(object);
}

const MonetaryFormatterKuheiga = (function()
{
    const T_DIGIT = 0;
    const T_DECIMAL = 1;
    const T_CURRENCY = 2;
    const T_SIGN = 3;
    const T_CHAR = 4;

    function MonetaryFormatterKuheiga()
    {
        this.setPattern("#,##0.00 ¤");
        this.separatorThousands = ".";
        this.separatorDecimals = ".";
        this.sign = "-";
    }

    function _parse(pattern)
    {
        const parsed = [];

        while (pattern.length > 0)
        {
            if (pattern.indexOf("\u00a4") === 0)
            {
                parsed.push({
                    type: T_CURRENCY
                });
                pattern = pattern.substr(1);
            }
            else if (pattern.indexOf("#,##0") === 0)
            {
                parsed.push({
                    type: T_DIGIT
                });
                pattern = pattern.substr(5);
            }
            else if (/^\.0+/.test(pattern))
            {
                const match = /^\.(0+)/.exec(pattern);

                parsed.push({
                    type: T_DECIMAL,
                    value: match[1].length
                });
                pattern = pattern.substr(match[0].length);
            }
            else if (pattern.indexOf("-") === 0)
            {
                parsed.push({
                    type: T_SIGN
                });
                pattern = pattern.substr(1);
            }
            else
            {
                parsed.push({
                    type: T_CHAR,
                    value: pattern.charAt(0)
                });
                pattern = pattern.substr(1);
            }
        }

        return parsed;
    }

    MonetaryFormatterKuheiga.prototype.setPattern = function(pattern)
    {
        this.pattern = [];
        pattern.split(";").forEach(subpattern =>
        {
            this.pattern.push(
                _parse(subpattern)
            );
        });
    };

    MonetaryFormatterKuheiga.prototype.setSeparators = function(separatorThousands, separatorDecimals)
    {
        this.separatorThousands = separatorThousands;
        this.separatorDecimals = separatorDecimals;
    };

    MonetaryFormatterKuheiga.prototype.setSign = function(sign)
    {
        this.sign = sign;
    };

    MonetaryFormatterKuheiga.prototype.format = function(value, currency)
    {
        let patternIndex = 0;
        let prefix = "";

        const displayCurrency = App.config.currency.format === "symbol"
            ? App.currencyPattern.symbols[currency]
            : Object.keys(App.currencyPattern.symbols).find(isoCode => App.currencyPattern.symbols[isoCode] === currency);

        currency = displayCurrency || currency;

        if (isNullOrUndefined(value) || (parseFloat(value) !== parseFloat(value)))
        {
            value = 0;
        }

        if (value < 0)
        {
            if (this.pattern.length > 1)
            {
                patternIndex = 1;
            }
            else
            {
                prefix = this.sign;
            }
        }

        return prefix + this.pattern[patternIndex].map((partial, index, pattern) =>
        {
            switch (partial.type)
            {
            case T_DIGIT: {
                if (value < 0)
                {
                    value *= -1;
                }
                // check if pattern include decimals to decide if digits should be rounded or not
                const roundDigits = !pattern.some(subpattern => subpattern.type === T_DECIMAL);

                // cut decimal places instead of rounding
                // revert the value to insert thousands separators next
                let digits = (roundDigits ? Math.round(value * 100) / 100 : parseInt(value))
                    .toFixed(0)
                    .split("").reverse().join("");

                // insert thousands separator
                for (let i = 3; i < digits.length; i += 4)
                {
                    digits = digits.substr(0, i) + this.separatorThousands + digits.substr(i);
                }

                // revert back again
                digits = digits.split("").reverse().join("");

                return digits;
            }
            case T_DECIMAL: {
                const numberOfDecimals = parseInt(partial.value);

                let result = Math.round(value * Math.pow(10, numberOfDecimals))
                    .toFixed(0)
                    .substr(-1 * numberOfDecimals, numberOfDecimals);

                while (result.length < numberOfDecimals)
                {
                    result = "0" + result;
                }

                return this.separatorDecimals + "<span class=\"after-dot\">" + result + "</span>";
            }
            case T_CURRENCY: {
                return currency;
            }
            case T_SIGN: {
                return this.sign;
            }
            case T_CHAR: {
                return partial.value;
            }
            default: {
                console.warn("Unkown pattern type: " + partial.type);
                return "";
            }
            }
        }).join("");
    };

    return MonetaryFormatterKuheiga;
})();

/*
 * Dirty component replace
 * 1200: { items: 5 }
 */
Vue.component("carousel", {

    template: `<div class="row">
        <div class="col-12 col-lg-12">
            <div class="list-item-carousel owl-carousel owl-theme owl-single-item" ref="carouselContainer">
                <slot-component v-for="(item, index) in $slots.items" :key="index" :vnode="item" />
            </div>
        </div>
    </div>
    `,

    components: {
        SlotComponent: {
            functional: true,
            render: (createElement, context) => context.data.attrs.vnode
        }
    },

    props: {
        itemsPerPage: {
            type: Number,
            default: 4
        }
    },

    computed:
    {
        columnWidths()
        {
            const itemsPerPage = Math.min(Math.max(this.itemsPerPage, 1), 4);

            return [
                "col-12",
                itemsPerPage === 1 ? "col-sm-12" : "col-sm-6",
                "col-md-" + (12 / itemsPerPage)
            ];
        }
    },

    mounted()
    {
        this.$nextTick(() =>
        {
            this.initializeCarousel();
        });
    },

    updated()
    {
        this.initializeCarousel();
    },

    methods:
    {
        initializeCarousel()
        {
            if (this.$slots.items && this.$slots.items[0].tag)
            {
                const $owl = $(this.$refs.carouselContainer);

                // do not render, if no html element is inside of the carousels container
                if (!$owl.children().length)
                {
                    return;
                }

                $owl.owlCarousel({
                    onInitialized()
                    {
                        $owl.find(".owl-carousel.owl-loaded").each(function()
                        {
                            $(this).trigger("refresh.owl.carousel");
                        });
                    },
                    autoHeight: true,
                    dots: true,
                    items: this.itemsPerPage,
                    responsive: {
                        0: {
                            items: 1
                        },
                        576: {
                            items: this.itemsPerPage > 1 ? 2 : 1
                        },
                        768: {
                            items: this.itemsPerPage > 3 ? 3 : this.itemsPerPage
                        },
                        992: {
                            items: this.itemsPerPage
                        },
                        1200: {
                            items: $("body").hasClass("page-singleitem") ? 4 : 5
                        }
                    },
                    lazyLoad: false,
                    loop: false,
                    margin: 30,
                    mouseDrag: true,
                    nav: true,
                    navClass: [
                        "owl-single-item-nav left carousel-control list-control-special",
                        "owl-single-item-nav right carousel-control list-control-special"
                    ],
                    navContainerClass: "",
                    navText: [
                        "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                        "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
                    ],
                    smartSpeed: 350
                });
            }
        }
    }
});

Vue.component("kuheiga-image-carousel", {

    delimiters: ["${", "}"],

    template: `<div itemscope itemtype="http://schema.org/Thing" class="single-item-kh-carousel">
    <div id="thumb-carousel" class="slick-thumb-carousel" ref="thumbs">
      <div v-for="(imagePreview, index) in carouselImages">
        <img
            :data-index="index"
            :src="imagePreview.url"
            :alt="getAltText(imagePreview)"
            :title="getItemName()"
        >
      </div>
    </div>
    <div id="single-carousel" class="owl-carousel owl-theme owl-single-item" ref="single">
      <div v-for="image in singleImages">
          <a :href="image.url" data-lightbox="single-big-image-gallery">
              <img class="owl-lazy" :data-src="image.url" :data-zoom="image.url" :alt="getAltText(image)" :title="getItemName()" itemprop="image">
          </a>
      </div>
    </div>
    <div class="drift-pane" ref="driftpanel"></div>
  </div>`,

    props: {
        imageUrlAccessor:
        {
            type: String,
            default: "url"
        },
        pluginPath:
        {
            type: String,
            default: ""
        }
    },

    inject: {
        itemId: {
            default: null
        }
    },

    data()
    {
        return {
            currentItem: 0
        };
    },

    computed:
    {
        currentVariation()
        {
            return this.$store.getters[`${this.itemId}/currentItemVariation`];
        },

        carouselImages()
        {
            return this.$options.filters.itemImages(
                this.currentVariation.images,
                "urlPreview"
            ).slice(0, this.maxQuantity);
        },
        singleImages()
        {
            return this.$options.filters.itemImages(
                this.currentVariation.images,
                this.imageUrlAccessor
            ).slice(0, this.maxQuantity);
        }
    },

    watch: {
        currentVariation:
        {
            handler(val, oldVal)
            {
                if (val !== oldVal)
                {
                    setTimeout(() =>
                    {
                        this.reInitialize();
                    }, 1);
                }
            },
            deep: true
        }
    },

    mounted()
    {
        this.$nextTick(() =>
        {
            this.loadLightbox().then(() =>
            {
                this.initCarousel();
                this.initThumbCarousel();
            })
                .catch(event =>
                {
                    console.log("error while loading lightbox", event);
                });
        });
    },

    methods: {
        getImageCount()
        {
            return this.carouselImages.length;
        },

        reInitialize()
        {
            const $owl = $(this.$refs.single);

            $owl.trigger("destroy.owl.carousel");
            $owl.html($owl.find(".owl-stage-outer").html()).removeClass("owl-loaded");
            $owl.find(".owl-item").remove();

            const $thumbs = $(this.$refs.thumbs);

            $thumbs.slick("unslick");

            this.initCarousel();
            this.initThumbCarousel();
        },

        initCarousel()
        {
            const imageCount = this.getImageCount();
            const carouselSettings = {
                autoHeight: true,
                dots: this.showDots,
                items: 1,
                lazyLoad: true,
                rewind: true,
                margin: 10,
                mouseDrag: imageCount > 1,
                nav: imageCount > 1,
                navClass: [
                    "owl-single-item-nav left carousel-control",
                    "owl-single-item-nav right carousel-control"
                ],
                navContainerClass: "",
                navText: [
                    "<i class=\"owl-single-item-control fa fa-chevron-left\" aria-hidden=\"true\"></i>",
                    "<i class=\"owl-single-item-control fa fa-chevron-right\" aria-hidden=\"true\"></i>"
                ],
                smartSpeed: 350,
                onChanged: event =>
                {
                    const $thumb = $(this.$refs.thumbs);

                    if ($thumb.length > 0 && $thumb[0].slick)
                    {
                        $thumb.slick("slickGoTo", event.page.index);
                    }
                }
            };

            if (this.animationStyle !== "standard")
            {
                carouselSettings.animateOut = this.animationStyle;
            }

            $(this.$refs.single).on("initialized.owl.carousel", event =>
            {
                if (!this.isTouchDevice())
                {
                    const driftImgs = this.$refs.single.querySelectorAll("img");
                    const pane = this.$refs.driftpanel;

                    driftImgs.forEach(img =>
                    {
                        new Drift(img, {
                            hoverBoundingBox: true,
                            paneContainer: pane,
                            zoomFactor: 2,
                            inlinePane: false,
                            handleTouch: false
                        });
                    });
                }
            });

            $(this.$refs.single).owlCarousel(carouselSettings);

            if (!isNullOrUndefined(window.lightbox))
            {
                lightbox.option({
                    wrapAround: true,
                    albumLabel: this.$translate("Ceres::Template.singleItemLightboxImageShown")
                });
            }

            $(this.$refs.single).on("changed.owl.carousel", event =>
            {
                this.currentItem = event.page.index;
            });
        },

        isTouchDevice()
        {
            return (("ontouchstart" in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0));
        },

        initThumbCarousel()
        {
            $(this.$refs.thumbs).slick({
                vertical: true,
                slidesToShow: 5,
                slidesToScroll: 1,
                verticalSwiping: true,
                infinite: false,
                prevArrow: "<i class='slick-prev-i fas fa-angle-up'></i>",
                nextArrow: "<i class='slick-next-i fas fa-angle-down'></i>"
            });

            const this2 = this;

            $(this.$refs.thumbs).find(".slick-slide").on("click", function(event)
            {
                const slideClicked = $(this).attr("data-slick-index");

                this2.goTo(slideClicked);
            });
        },

        goTo(index)
        {
            const $owl = $(this.$refs.single);

            $owl.trigger("to.owl.carousel", [
                index,
                350
            ]);
        },

        getAltText(image)
        {
            return image && image.alternate ? image.alternate : this.$options.filters.itemName(this.currentVariation);
        },

        getItemName()
        {
            return this.$options.filters.itemName(this.currentVariation);
        },

        loadLightbox()
        {
            return new Promise((resolve, reject) =>
            {
                const script = document.querySelector("script#lightboxscript");

                if (!isNullOrUndefined(script))
                {
                    resolve();
                }
                else
                {
                    const script = document.createElement("script");

                    script.type = "text/javascript";
                    script.id = "lightboxscript";
                    script.src = `${this.pluginPath}/js/dist/lightbox.min.js`;

                    script.addEventListener("load", () => resolve(), false);
                    script.addEventListener("error", event => reject(event), false);

                    document.body.appendChild(script);
                }
            });
        }
    }
});

$(function()
{
    $("body").on("click", ".widget-filter-all-fl .facet-item .filter-toggle", function()
    {
        $(".widget-filter-all-fl .facet-item .collapse").collapse("hide");
    });
    setInterval(function()
    {
        $(".widget-filter-all-fl .facet-item .collapse.init").removeClass("init").collapse("hide");
    }, 200);
    $("body").on("click", "#toogle-filters", function()
    {
        $("#filterCollapse").slideToggle("slow");
    });

    $("body").on("change", "#CfourItemQuestionsForm [name=\"productid\"]", function()
    {
        const msgfield = $(this).parent().find("[name=\"message\"]");

        if (msgfield.length && msgfield.val().length <= 0)
        {
            msgfield.val("Artikel ID: " + $(this).val());
        }
    });
    $("#CfourItemQuestionsForm [name=\"productid\"]").change();

    /* <div class="model-button-container"><button type="button" class="btn btn-primary show-3d-modal">
        <span>View in 3D</span>
    </button></div>*/
    $("body").on("click", ".show-3d-modal", function()
    {
        const itemModal = $("#item3dModal");

        const modelSrc = $(this).attr("data-model");

        itemModal.find("model-viewer").attr("src", modelSrc);

        itemModal.modal("show");
    });

    if (window.location.href.indexOf("#3dModel") != -1)
    {
        $(".show-3d-modal").trigger("click");
    }
});
